.sscreen {
    text-align: center;
}

.sscreen-logo {
    height: 40vmin;
    pointer-events: none;
}

.sscreen-header {
    background-color: #153e7b;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.sscreen-link {
    color: #61dafb;
}

@media (prefers-reduced-motion: no-preference) {
    .sscreen-logo {
      animation: sscreen-logo-spin infinite 20s linear;
    }
}

@keyframes sscreen-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
}
  
.btMainScreen {
  border-radius: 25px;
  background-color: #03989e;
  padding: 20px 30px 20px 30px;
  color: #FFFFFF;
  border: 0px none; 
  font-size: 14pt;
  outline: none;
  cursor: pointer;
  text-transform: uppercase;
}
.btMainScreen:hover {
  background-color: #53aeb2;
  color: #065a5d;
}
.btMainScreen:focus {
  outline: none;
}