.iconGrey { color: #666666; }
.iconGreyDis { color: #9a9a9a; }
.iconGreyDis { color: #9a9a9a; }
.iconLightGrey { color: #89879F; }
.iconBlue { color: #1E73BE; }
.iconWhite { color: #FFFFFF; }
.iconGrey:hover { color: #9a9a9a; }
.iconBlue:hover { color: #1E73BE; opacity:.8 }

.lineBottom { padding: 5px 0px 5px 0px; border-bottom:1px solid #CCCCCC ; }
.fontGreen { color: #056d1d; }
.fontRed { color: #DC3545; }

.stTitle {
    font-size: 16pt;
    color: #1E73BE;
    font-weight: bold;
}
.stIndiTitle {
    font-size: 11pt;
    color: #89879F;
}
.st2Title {
    font-size: 18pt;
    color: #444;
    font-weight: 500;
}
.st3Title {
    font-size: 14pt;
    color: #444;
    font-weight: bold;
}
.st4Title {
    font-size: 13pt;
    color: #444;
    font-weight: bold;
}
.smText {
    font-size: 11pt;
}
.warningText {
    font-size: 10pt;
    color: #C44343;
    font-style: italic;
}
.notesText {
    font-size: 10pt;
    color: #787878;
    font-style: italic;
}
.linkText {
    font-size: 10pt;
    color: #666666;
}
.linkText:hover { color: #4043f7; }
.main-content {
    position: relative;
    flex: auto;
    margin: 130px 20px 20px 330px;
    padding: 0px;
}
.card{
    border:none;
    -webkit-box-shadow: 5px 5px 15px 5px #e5e5e5; 
    box-shadow: 5px 5px 15px 5px #e5e5e5;
    border-radius:12px;
    padding:2.25rem;
}
.widget{
    color:#fff;
    border:none;
    -webkit-box-shadow: 5px 5px 15px 5px #e5e5e5; 
    box-shadow: 5px 5px 15px 5px #e5e5e5;
    border-radius:12px;
    padding:2.25rem;
    background: rgb(30,115,190);
    background: linear-gradient(180deg, rgba(30,115,190,1) 0%, rgba(30,115,190,1) 35%, rgba(97,165,226,1) 100%);
    max-height:400px;
    overflow:scroll;
}
.widget-item{
    border-radius:6px;
    cursor:pointer;
    transition:.2s;
    padding:10px 0;
}
.widget-item:hover{
    background-color: #74d7ff3b;
}
.widget-item p{
    margin:0
}
.widget-item strong{
    font-size:110%;
}
.widget .widget-interact{
    height:100%;
}

/* BUTTON */
button {
    border-radius: 8px;
    border: 0px none;
    text-align: center;
}
button:focus { outline: none; }
.backButton, .pickerButton, .subgenButton {
    color: #FFFFFF;
    border: none;
    border-radius: 3px;
    padding: 5px 10px 5px 10px;
    font-size: .8em;
    text-transform: uppercase;
    cursor: pointer;
    outline: none;
}
.backButton, .pickerButton { background-color: #999999; }
.subgenButton { background-color: #1ea3be; }
.genButton, .submitButton, .redButton, .greenButton {
    border-radius: 8px;
    padding: 12px 12px 10px 12px;
    color: #FFFFFF;
    outline: none;
    text-transform:capitalize;
    font-weight:bold;
}
.genButton, .submitButton { background: transparent radial-gradient(closest-side at 50% 50%, #1E73BE 0%, #1F72BA 100%) 0% 0% no-repeat padding-box; }
.redButton { background: transparent radial-gradient(closest-side at 50% 50%, #DC3545 0%, #DC3545 100%) 0% 0% no-repeat padding-box;}
.greenButton { background: transparent radial-gradient(closest-side at 50% 50%, #28A745 0%, #28A745 100%) 0% 0% no-repeat padding-box;}
.noneButton { background: transparent ; }
.trspButton {
    font-size: 13px;
    font-weight: 700;
    color: #999999;
    padding: 3px 15px;
    background: none;
    border-radius: 5px;
    border: 1px solid #cdd0d6;
}
.trspButton:hover{
    background: #D7DAE3;
    color:#999999;
}
/* --- HEADER ------------ */
.header-main {
    width: 100%;
    background: rgb(30,115,190);
    background: linear-gradient(90deg, rgba(30,115,190,1) 0%, rgba(30,115,190,1) 51%, rgba(26,161,232,1) 100%);
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 300;
}
.header-left {
    height: 100px;
    background-color: #1E73BE;
}
.header-logo {
    margin: 20px 10px 0px 10px;
    height: 50px;
}
.header-logo-t {
    font-size: 18pt;
    color: #FFFFFF;
    font-weight: bold;
    margin-left: 30px;
}
.spacer{
    height:60px;
    background:#fff;
    width:1px;
    margin-left: 160px;
    margin-top:20px;
}
.header-menu { 
    height: 100px;
    position: fixed; 
    right: 0px;  
}
.header-icon {
    border: 0px none;
    border-radius: 6px;
    background-color: #EBEEF6;
    padding: 10px;
}
.header-picprofile {
    border: 0px none;
    border-radius: 6px;
    width: 55px;
}
.header-profile {
    min-width: 230px;
    color:#fff;
}
.header-pop {
    font-weight:bold;
    height: 50px;
    border-bottom:1px solid #CCCCCC ;
    display: flex;
    font-size: 14pt;
    color: #444;
}
/* --- SIDEBAR ------------ */
.sidebar-main {
    position: fixed;
    top: 100px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 300px;
    background-color: #FFFFFF;
    overflow-y: auto;
}
.sidebar-content {
    margin: 30px 20px 30px 20px;
}
.iconMenu {
    color: #999999;
    width: 50px;
}
.smLeftMenu { font-size: 9pt; color: #999999; padding: 10px 0px 10px 0px; }
.leftMenu {
    color: #999999;
}
.own-style-1 {
    color: #666666;
}
.own-style-1:hover {
    background-color: #E7E7E7 !important; 
    border-radius: 0px 20px 20px 0px !important;
}

/* FORM */
input[type="text"]::placeholder,
input[type="password"]::placeholder,
select::placeholder,
textarea::placeholder {
    color: #A1A1A1;
}
.inputSearch { 
    width: 300px; 
    border: 1px solid #ced4da;
    border-radius: .25rem;
    background-color: #FFFFFF;
    line-height: 1.5;
    padding:.375rem .75rem;
    outline: none;
    font-size: 11pt;
    color: #666666;
}
.labelFormL {
    width: 200px;
    line-height: 2;
}
.mgL250 { margin-left: 250px; }
.FormField2__Input {
    width: 70%;
}
/* TABLE */
.row-field {
    display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;
}
.table-striped tbody tr{
    transition:.2s;
}
.table-striped tbody tr:hover{
    background-color:#e2ecf5;
    cursor:default;
}
.font-sublist { font-size:10.5pt; color:#89879F; }
/* CLIENT DETAIL */
.detailJoined,
.detailContent {font-weight:normal; color:#89879F; font-size:14px}
.due { font-weight:bold; color:#C44343; font-size:14px }
.eventContainer, .picContainer {
    border: 1px solid #D7DAE3;
    border-radius:12px;
    overflow-y: auto;
    list-style:none;
}
.eventContainer { 
    margin-top:20px;
    max-height:300px;
    padding: 32px 18px;
}
.picContainer {
    margin-top:5px;
    margin-bottom: 10px;
    max-height: 220px;
    padding: 10px;
}
.eventContainer li, 
.picContainer li 
{
    border-bottom:1px solid #D7DAE3;
    margin: 12px 0;
}
.eventContainer li:last-child,
.picContainer li:last-child
{
    border-bottom:none;
}
.event { margin-bottom:0; }
.eventDate { font-size:12px; color:#89879F; }
.react-date-picker__wrapper{
    height:calc(1.5em + .75rem + 6px);
    border: 1px solid #c7c7c7 !important;
    padding: .375rem .75rem;
    border-radius: .25rem;
    color:#495057;
}
.datepickers {
    border-color:#999999;
    color: #666666 !important;
    z-index: 500;
}
/* MEMBER DETAIL */
.memberPhoto{
    width: 100px;
    height: 100px;
    border-radius: 50%
}
/* RENEW DETAIL */
.smallForm {
    border-radius: 10px;
    padding: 20px;
    background-image: linear-gradient(to bottom, #EEEEEE , #F7F7F7);
}
.memberListPhoto{
    border: 0px none;
    width: 45px;
    border-radius: 50%
}
/* TABS */
.tabs-class {
    -webkit-tap-highlight-color: transparent;
    font-family: "Roboto Light";
}
.tabs-class__tab-list {
    border-bottom: 1px solid #aaa;
    margin: 0 0 10px;
    padding: 0;
}
.tabs-class__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;
}
.tabs-class__tab--selected {
    background: #fff;
    border-width: 1px 1px 0px 1px;
    border-color: #aaa;
    color: #555555;
    border-radius: 5px 5px 0 0;
    font-weight: bold;
}
.tabs-class__tab--disabled {
    color: #CCCCCC;
    cursor: default;
}
.tabs-class__tab-panel {
    display: none;
    padding:20px;
    border-style:solid;
    border-color: #aaa;
    border-width: 0px 1px 1px 1px;
    margin-top:-10px;
}
.tabs-class__tab-panel--selected {
    display: block;
    background-color: #FFF;
}
.react-tabs__tab--selected {
    background: #fff;
    border-width: 1px 1px 0px 1px;
    border-color: #aaa;
    color: #555555;
    border-radius: 5px 5px 0 0;
  }
/* ACCORDION */
.accord.accordion {
    margin-top: 30px;
}
.accord .accordion-header {
    margin-bottom: 0;
    margin-top: 15px;
}
.accord .accordion-body {
    padding: 15px;
    margin-bottom: 15px;
    border: 1px solid #e9ecef;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}
.accord .accordion-button {
    display: block;
    width: 100%;
    padding: 10px 15px;
    text-align: left;
    font-size: 13pt;
    background-color: #e9ecef;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.accord .accordion-button.collapsed {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;    
}
.accord .toggle-open,
.accord .toggle-close {
    float: right;
}
.accord .toggle-open,
.accord .collapsed .toggle-close {
    display: none;
}
.accord .collapsed .toggle-open,
.accord .toggle-close {
    display: inline;
}
/* LIST */
.list-alpha {
    counter-reset: list;
}
.list-alpha > li {
    list-style: none;
}
.list-alpha > li:before {
    content: counter(list, upper-alpha) ". ";
    counter-increment: list;
    margin-left: -20px;
}