/* Popup style */
.popup-box {
    position: fixed;
    background-color: rgba(0,0,0,0.75); /* Black w/ opacity */
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 501; /* Sit on top */
  }
   
  .box {
    position: relative;
    margin: 0 auto;
    height: auto;
    max-height: 70vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    overflow: auto;
  }
  .close-icon {
    cursor: pointer;
    color: #c50606;
  }