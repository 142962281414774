.iconWhite { color: #FFFFFF; }
.iconGrey { color: #333333; }
.iconGrey:hover { color: #666666; }
.iconGreen { color: #10ca93; }
.iconRed { color: #ca1010; }
.white-font { color: #fff; }
body {
  background-color: #f2f7fb;
}
div, label {
  font-size: 12pt;
}
.stTitle {
  font-size: 16pt;
  color: #1E73BE;
  font-weight: bold;
}
.stIndiTitle {
  font-size: 11pt;
  color: #89879F;
}
.st3Title {
  font-size: 14pt;
  color: #444;
  font-weight: bold;
}
.st4Title {
  font-size: 13pt;
  color: #444;
  font-weight: 500;
}
.fontlink {
  color: #1E73BE;
}
.w220x { width: 220px; }
.w220x-min { width: calc(100% - 220px); }
/* --- HEADER ------ */
.cheader {
  z-index: 100;
  background: #fff;
  position: fixed;
  width: calc(100% - 0%);
  height: 70px;
  display: flex;
  top: 0;
  box-shadow: 1px 1px 12px #e1e1e1;
}
.cheader .cheader-menu {
  /*width: calc(100% - 0%);  */
  height: 70px;
  display: flex;
  justify-content: center;
  position: fixed;
  right: 0;
  /*justify-content: space-between;
  align-items: center; */
  padding: 0 20px;
}
.cheader-menu .iconGrey{
  cursor:pointer;
}
.cheader-profile {
  display: flex;
  align-items: center;
  min-width: 200px;
}
.cheader-picprofile {
  margin: 10px 10px;
  height: 45px;
  border-radius: 10px;
}
.font-user-name {
  font-size: 11pt;
  font-weight: 500;
}
.font-user-company-name {
  font-size: 9pt;
  font-weight: 300;
  color: #707070;
}
/* --- SIDEBAR ------ */
.csidebar {
  z-index: 300;
  background: #fff;
  position: fixed;
  top: 0;
  width: 250px;
  height: 100vh;
  transition: 0.3s;
  transition-property: width;
  /*overflow-y: hidden; */
  margin: 0px;
}
.csidebar-content {
  margin: 0px 10px 0px 10px;
  overflow-y: auto;
  /*height: 680px;*/
  height: calc(100% - 130px);
}
/*.csidebar .csidebar-menu {
  overflow: hidden;
}
*/
.csidebar-btn {
  color: #fff;
  position: absolute;
  margin-left: 220px;
  font-size: 22px;
  font-weight: 900;
  cursor: pointer;
  transition: 0.3s;
  transition-property: color;
}

.company-profile {
  display: flex;
  align-items: center;
  background: #1e3c72;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #2a5298, #1e3c72);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #2a5298, #1e3c72); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  margin-bottom: 35px;
}
.company-logo {
  margin: 10px 10px;
  height: 50px;
  border-radius: 50%;
}
.company-title {
  color: #fff;
  font-weight: 500;
  font-size: 12pt;
  line-height: 1.2em;
}
.cs-profile {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e1e1e1;
  margin-bottom: 20px;
  padding-bottom: 25px;
}
.cs-profile-img {
  margin: 10px 10px;
  height: 45px;
  border-radius: 10px;
}
.cs-detail { line-height: 1.2em; }
.font-cs-name {
  font-size: 11.5pt;
  font-weight: 500;
}
.font-cs-company-name {
  font-size: 10pt;
  font-weight: 300;
  color: #707070;
}
.font-main-menu {
  color: #999999;
  font-size: 10pt;
  margin: 10px;
}

/* --- MAIN CONTAINER ------ */
.cmain-container {
  /*width: calc(100% - 250px); */
  width: calc(100% - 1%);
  margin-top: 70px;
  margin-left: 250px;
  padding: 35px;
  background-color: #f2f7fb;
  background-size: cover;
  min-height: 100vh;
  transition: 0.3s;
}
.cmain-content {
  position: relative;
  flex: auto;
  margin: 100px 20px 20px 280px;
  padding: 0px;
  background-size: cover;
  transition: 0.3s;
}
.cappmain-content {
  position: relative;
  flex: auto;
  margin: 20px 20px 20px 20px;
  padding: 0px;
  background-size: cover;
  transition: 0.3s;
}
.back-area {
  display: flex; justify-content: flex-end;
}
.card-main {
  padding: 32px;
  font-size: 14px;
  margin-bottom: 14px;
  border-radius: 10px;
}
.card-main-white { background: #FFFFFF; }
.card-main-blue { background: #00afef; }
/* --- Start - MY PROFILE ------ */
.darkBlue-bg { background-color: #24365c !important; }
.lightBlue-bg { background-color: #00afef !important; }
.orange-bg { background-color: #ff9f00 !important; } 
.darkorange-bg { background-color: #ff3c00 !important; }
.base-bg { background-color: #f2f7fb !important; }
.lightgrey-bg { background-color: #F3F3F3 !important; }
.div-title-card {
  font-size: 15pt;
  color: #444;
  font-weight: 500;
  padding-bottom: 12px;
  border-bottom: 1px solid #bec1cb;
}
.label-title-card {
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 12px;
  border-bottom: 1px solid #d7dae3;
}
.quantity-seat span {
  font-size: 20px;
  font-weight: 600;
  margin-right: 5px;
  margin-left: 5px;
}
.done-text { font-size: 13pt; color: #89879f; }
.notes-text { 
  font-size: 10pt;
  color: #787878;
  font-style: italic;
}
.sub-font { font-size: 11pt; color: #89879f; }

.table-head { background-color: #2a5298; color: #fFFFFF; }

/* --- End - MY PROFILE ------ */
/*.row-field {
  display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;
}*/

/*
.card-request {
  background: #00afef;
  padding: 20px;
  font-size: 14px;
  margin-bottom: 14px;
  border-radius: 10px;
  min-height: 50vh;
}
*/
.list-request {
  border-bottom: 1px solid #ebeef6;
  display: flex;
  justify-content: space-between;
  padding: 16px 0px;
}
.list-request p {
  font-size: 14pt;
  font-weight: 700;
  color: #fff;
  margin-bottom: 15px;
}
.list-request span {
  font-size: 11pt;
  color: #fff;
}
.list-request .title-request {
  font-size: 12pt;
  font-weight: 700;
  margin-bottom: 0px;
}
.list-request .content-request {
  font-size: 11pt;
  margin-bottom: 0px;
}
.list-request .smcontent-request {
  font-size: 10pt;
  margin-bottom: 0px;
}
.list-request .date-request {
  font-size: 9pt;
  font-weight: 100;
  margin-bottom: 0px;
}
.list-request:last-child {
  margin-bottom: 20px;
  border: none;
}
.more-request {
  color: #FFF;
  font-size: 11pt;
  font-weight: 700;
  margin-top:20px;
  height: 40px;
  cursor: pointer;
}

/* --- BUTTON ------ */
.submitButton {
  border-radius: 10px;
  padding: 12px 20px 10px 20px;
  color: #FFFFFF;
  outline: none;
  text-transform:capitalize;
  font-weight:bold;
}
.transpButton {
  font-size: 13px;
  font-weight: 700;
  color: #fff;
  padding: 3px 20px;
  background: none;
  border-radius: 5px;
  border: 1px solid #fff;
  transition:.2s;
}
.transpButton:hover{
  background: #fff;
  color:#00afef;
}
.submitButton { background: transparent radial-gradient(closest-side at 50% 50%, #1E73BE 0%, #1F72BA 100%) 0% 0% no-repeat padding-box; }
/* ---- NEWS -------- */
.profile-card,
.general-card{
  background:#fff;
  min-height:300px;
  border-radius:8px;
  padding:20px;
  margin-bottom:20px;
}

.general-card img{
  max-width:100%;
}

.general-card p img{
  text-align:center;
}

.news-detail{
  /*padding:20px 40px;*/
  padding:10px;
}
.scenery{
  width:100%;
  height:250px;
  /*background-image:url(https://images.pexels.com/photos/323705/pexels-photo-323705.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260);*/
  background-repeat:no-repeat;
  background-size:cover;
  margin:auto;
}
.user-photo{
  max-width:160px;
  height:160px;
  border-radius:80px;
  border-width: 6px;
  border-color:#fff;
  border-style:solid;
  background-color:#1e73be;
  position:relative;
  bottom:50px;
  left:50px;
  /*background-image:url(https://images.pexels.com/photos/3671083/pexels-photo-3671083.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260);*/
  background-repeat:no-repeat;
  background-size:cover;
}
.profiles .row{
  /*padding:0 12px;*/
  padding : 0;
}
.profile{
  min-height:180px;
  background: #232526;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #414345, #232526);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #414345, #232526); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */  

  /*background: #085078; */ /* fallback for old browsers */
  /*background: -webkit-linear-gradient(to right, #85D8CE, #085078); */  /* Chrome 10-25, Safari 5.1-6 */
  /*background: linear-gradient(to right, #85D8CE, #085078); */ /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
  color:#fff;  
}
.profile a{
  color:#fff !important;
}
.memberPhoto{
  width:120px;
  height:120px;
  border-radius:50%;
  border:4px solid #fff;
}
.account-photo{
  /*background-image:url(https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260);*/
  background-repeat:no-repeat;
  background-size:cover;
  max-width:160px;
  max-height:160px;
  border-radius:80px;
  margin:20px auto;
}

.account-info{
  text-align:center;
  border-bottom:1px solid #e1e1e1;
  padding-bottom:12px;
  margin-bottom:12px;
}

.account-info p{
  margin-bottom:6px;
}

.account-contact{
  padding-top:18px;
  text-align:center;
  list-style:none;
}

.account-contact li{
  border-right:1px solid #e1e1e1;
  cursor:pointer;
}

.account-contact li:last-child{
  border-right:none;
  border-bottom:none;
}

.user-info{
  padding-top:40px;
  padding-left:80px;
}

.user-info .badge{
  padding:8px;
  margin-left:10px;
}

.user-info p{
  margin-bottom:.8rem;
}
.subscription-profile{
  background:#1e73be;
  height:40px;
  color:#fff;
  border-radius:8px;
}
.row .widget-container-left{
  padding-right:2px;
}
.widget-expiration{
  background: #485563;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #29323c, #485563);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #29323c, #485563); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  min-height:100px;
  border-radius:8px;
  margin-top:20px;
  padding:20px;
  color:#fff;
}
.widget-expiration h3,
.widget-submission h3,
.widget-profile h3,
.widget-account h3{
  font-size:110%;
  cursor:default;
}

.widget-expiration ul,
.widget-submission ul,
.widget-news ul{
  list-style:none;
  padding:10px 0;
}
.news-related{
  list-style:none;
  padding:10px 20px;
}

.widget-expiration ul li{
  padding:6px 0;
  border-bottom:1px solid #ffffff4d;
  cursor:pointer;
}
.widget-expiration ul li:hover{
  background:#ffffff4d;
}
.widget-expiration ul li:last-child{
  border-bottom:none;
}
.widget-submission{
  background: #00c6ff;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #0072ff, #00c6ff);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #0072ff, #00c6ff); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */  
  min-height:100px;
  border-radius:8px;
  margin-top:20px;
  padding:20px;
  color:#fff;
}
.widget-submission ul li{
  padding:6px 0;
  border-bottom:1px solid #ffffff4d;
  cursor:pointer;
}
.widget-submission ul li:hover{
  background:#ffffff4d;
}
.widget-submission ul li:last-child{
  border-bottom:none;
}
.widget-news{
  background:#fff;
  min-height:300px;
  border-radius:8px;
  padding:20px;
  margin-top:20px;
}

.widget-news li,
.news-list-item {
  margin-bottom:18px;
  cursor:pointer;
  padding:18px;
  border-radius:8px;
  transition: .2s ease-in;
  overflow:hidden;
}
.widget-news li:hover,
.news-list-item:hover {
  background: #e1e1e142;
}
.widget-news h3{
  font-size:110%;
  cursor:default;
}
.news-title{
  font-size:140%;
  margin-bottom:12px;
  font-weight:700;
  margin-bottom:12px !important;
}
.news-info{
  font-size:80%;
  margin-bottom:12px !important;
}
.news-thumbnail{
  text-align:center;
  margin-bottom:12px !important;
  max-height:250px;
}
.news-detail-content{
  margin-top:50px;
}
.expire{
  font-size:80%;
}
.items p{
  margin-bottom:0;
}
.items-related p{
  min-height:100px;
  /*font-size:120% !important; */
}
.items-related .news-related-thumbnail{
  margin-bottom:16px !important;
  border: 1px solid #ddd;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.news-related-title{
  font-size: 12pt;
  color: #444;
  font-weight: 500;
  text-align:left !important;
}
/* MakeStyles Adjustment */

.makeStyles-content-3,
.makeStyles-content-33{
  /*border-radius:0px 24px 24px 0px !important; */
  border-radius:24px !important;
}

.makeStyles-labelRoot-8{
  border-radius:24px !important;
}

.makeStyles-labelRoot-8:hover{
  background:none !important;
}

.own-style-1{
  border-radius:24px;
}

.own-style-1:hover{
  background: none !important;
}

.MuiTreeItem-label{
  /*border-radius:0px 24px 24px 0px; */
  border-radius:24px;
}

.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover, .MuiTreeItem-root.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label{
  background:none !important;
}

.burger-button{
  cursor:pointer;
  visibility:hidden;
  margin-left:12px;
  background: linear-gradient(to right, #2a5298, #1e3c72);
  padding:2px;
  color:#fff;
  width:80px;
  height:auto;
  border-radius:4px;
}

@media only screen and (max-width: 1089px) {
  .user-info{
    padding-top:0px;
    padding-left:20px;
  }
}

@media only screen and (max-width: 1089px) {
  .user-info{
    text-align:center;
  }
}

@media only screen and (max-width: 480px) {
  .burger-button{
    visibility:visible;
  } 
 .cmain-content{
    /*margin:6px; */
    margin: 100px 20px 20px 20px;
    padding: 0px;
  }
  /*.cheader .cheader-menu{
    position:relative;
    padding:0;
  }*/
  .user-photo{
    left:30%;
  }
  .profile{ 
    height: 100%;
  }

}

/* --- Start - RADIO Button as CARD ------ */
.card-input-element {
  display: none;
}

.card-input {
  padding: 20px;
  font-size: 14px;
  margin-bottom: 10px;
  border-radius: 10px;
  /* margin: 10px;
  padding: 00px; */
}

.card-input:hover {
  cursor: pointer;
}

.card-input-element:checked + .card-input {
   box-shadow: 0 0 1px 1px #90cbfa;
}