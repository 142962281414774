.border { border: 1px solid #333; }


@font-face {
    font-family: Roboto Light;
    src: url('../fonts/roboto/roboto.light.ttf') format('truetype'); 
}
body {
    margin: 0;
    background-color: #f3f5ff;
    font-size: 11pt;
    color: #555555;
}
img { border-width: 0px; }
div, button, span, td, th,
input[type="text"],
input[type="password"],
textarea, select {
    font-family: 'Roboto', Poppins-Regular, Helvetica, Arial, 'Open Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
div, span, td, th { 
    word-wrap: break-word;
    white-space: pre-line;
}
a { text-decoration:none; color: #666666; }
a.link, a.visited, a.active, a.hover { text-decoration:none; color: #666666; }
blockquote {
    margin: 1rem 0;
    padding: 10px 15px;
    background: #eee;
    border-radius: 4px;
}
*:focus { outline: none; }

.cursor-on { cursor: pointer; }
.noScroll { overflow: hidden;}
.scroll-y { overflow-x: hidden; overflow-y: auto;}

.main-container { width: 100%; padding: 0px; }
.flex-container { display:flex; }
.divHide { display : none; }
.divRight {
    margin: 0;
    padding: 0;
    display: flex; justify-content: flex-end;
}
.divMiddle, .divCenter, .center-screen {
    justify-content: center;
    display: flex;
    flex-direction: column;
}
.divCenter, .center-screen { 
    align-items: center;
}
.center-screen { 
    min-height: 100vh;
}

.pad0 { padding: 0px; }
.pdT20 { padding-top: 20px;}
.mgT03 { margin-top: 3px; }
.mgT05 { margin-top: 5px; }
.mgT10 { margin-top: 10px; }
.mgT20 { margin-top: 20px; }
.mgT30 { margin-top: 30px; }
.mgT50 { margin-top: 50px; }
.mgB10 { margin-bottom: 10px; }
.mgB20 { margin-bottom: 20px; }
.mgB30 { margin-bottom: 30px; }
.mgB50 { margin-bottom: 50px; }

.mgR05 { margin-right: 5px; }
.mgR10 { margin-right: 10px; }
.mgR20 { margin-right: 20px; }
.mgR30 { margin-right: 30px; }
.mgL10 { margin-left: 10px; }
.mgL15 { margin-left: 15px; }
.mgL20 { margin-left: 20px; }
.mgL30 { margin-left: 30px; }
.w10p { width: 10%; }
.w20p { width: 20%; }
.w30p { width: 30%; }
.w40p { width: 40%; }
.w50p { width: 50%; }
.w60p { width: 60%; }
.w70p { width:70%; }
.w80p { width:80%; }
.w90p { width:90%; }
.w100p { width:100%; }
.h100p { height: 100%; }
.h100v { height: 100vh; }
.w150x { width: 150px;}
.w50x { width: 50px; }
.w280x { width: 280px; }
.w280x-min { width: calc(100% - 280px); }
.w350x { width: 350px; }
.w350x-min { width: calc(100% - 350px); }

.frg15e { display: flex; flex-direction: row; column-gap: 15px; align-items: flex-end; }

.textWrap { word-wrap: break-word; }
.textBold { font-weight: bold; }
.textItalic { font-style: italic; }
.textCenter { text-align : center; }
.textLeft { text-align : left; }
.textJustify { text-align: justify; text-justify: inter-word; }
.textMiddle { display: flex; align-items: center; }
.textRight { display: flex; justify-content: flex-end; }

.bgColRed { background-color: #FDECEA; }
.errText {
    font-size: 9.5pt;
    color: #611A15;
    background-color: #FDECEA;
    padding: 8px;
    width: 100%;
    border : 0px none;
    border-radius: 4px;
}
.fontGrey { color: #89879F; }
.fontRed { color: #611A15; }
.heading1 { font-size: 32pt; }


/* LOGIN PAGE */
.login-area {
    height: 500px;
    width: 580px;
    background-color: #FFFFFF;
}
.btLogin {
    width: 100%;
    height: 50px;
    background-color: #1E73BE;
    color: #FFFFFF;
    font-size: 12pt;
    outline: none;
}
.signin-google-button {
    width: 220px;
    border : 1px solid #4285f4;
    border-radius: 5px;
    box-shadow: 1px 1px 1px #999999;
    cursor: pointer;
    background-color: #FFFFFF;
}
.signin-google-button-left {
    margin:15px 15px 10px 15px;
    
}
.signin-google-button-right {
    width: 100%;
    color: #FFFFFF;
    background-color: #4285f4;
    display: flex; 
    align-items: center; 
    justify-content: center; 
    font-weight: bold;
}

/* ---- SUBSCRIPTION ---------------------------------------- */
.subs-block {
    background: #eee;
    margin-bottom: 10px;
    padding: 15px 15px 1px;
    cursor: pointer;
    position: relative;
    border-radius: 8px;
}
.subs-block:last-child {
    margin-bottom: 0;
}
.subs-block:hover .subs-block-title {
    color: #198cf0;
}
.subs-block-title {
    font-size: 18px;
    margin-bottom: 5px;
    margin-top: -5px;
}
.subs-block-status {
    background: #198cf0;
    font-size: 12px;
    padding: 3px 12px;
    margin-bottom: 15px;
    color: #fff;
    border-radius: 25px;
}
.subs-block-date {
    position: absolute;
    top: 17px;
    right: 15px;
}
.subs-block-view {
    position: absolute;
    bottom: 17px;
    right: 15px;
}
/* ---- LOADING ---------------------------------------- */
.loading {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: visible;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
/* Transparent Overlay */
.loading:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.75);
}
  
.loading:not(:required):after {
    content: '';
    display: block;
    font-size: 10px;
    width: 1em;
    height: 1em;
    margin-top: -0.5em;
    -webkit-animation: spinner 1500ms infinite linear;
    -moz-animation: spinner 1500ms infinite linear;
    -ms-animation: spinner 1500ms infinite linear;
    -o-animation: spinner 1500ms infinite linear;
    animation: spinner 1500ms infinite linear;
    border-radius: 0.5em;
    -webkit-box-shadow: rgba(180, 180, 180, 0.75) 1.5em 0 0 0, rgba(180, 180, 180, 0.75) 1.1em 1.1em 0 0, rgba(180, 180, 180, 0.75) 0 1.5em 0 0, rgba(180, 180, 180, 0.75) -1.1em 1.1em 0 0, rgba(180, 180, 180, 0.5) -1.5em 0 0 0, rgba(180, 180, 180, 0.5) -1.1em -1.1em 0 0, rgba(180, 180, 180, 0.75) 0 -1.5em 0 0, rgba(180, 180, 180, 0.75) 1.1em -1.1em 0 0;
    box-shadow: rgba(180, 180, 180, 0.75) 1.5em 0 0 0, rgba(180, 180, 180, 0.75) 1.1em 1.1em 0 0, rgba(180, 180, 180, 0.75) 0 1.5em 0 0, rgba(180, 180, 180, 0.75) -1.1em 1.1em 0 0, rgba(180, 180, 180, 0.75) -1.5em 0 0 0, rgba(180, 180, 180, 0.75) -1.1em -1.1em 0 0, rgba(180, 180, 180, 0.75) 0 -1.5em 0 0, rgba(180, 180, 180, 0.75) 1.1em -1.1em 0 0;
}
@-webkit-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
}
@-moz-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
}
@-o-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
}
@keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
}
